import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink, useHistory } from "react-router-dom";

const CbMakeAnAppointmentHome = () => {
  const history = useHistory();
  return (
    <>
      <div className="cd_make_An_Appintment_section">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <div className="cd_text_and_btn_box">
                <h2>Atlanta's Premier Lab Grown Diamond Specialists</h2>
                <p>Showroom located on Main Street in Downtown Woodstock</p>
                <div className="cd_homer_make_btn_box">
                  <button className="cd_btn_rama_color" onClick={()=>history.push("/make-an-appointment")}>
                    Make an Appointment
                  </button>
                  <button className="cd_btn_rama_color" onClick={()=>history.push("/ringsettings")}> Build Her Ring</button>
                </div>
              </div>
              <div className="cd_SUBtext_and_btn_box">
                <h2>We Are Available 7 Days!</h2>
                <p>
                  We welcome walk-in customers Tuesday to Friday: 10am - 5:30pm and
                  on Saturday: 10am - 4pm<br></br>
                  If you prefer Sunday or Monday, or later in the day, simply
                  <br></br>
                  schedule an appointment by calling{" "}
                  <a href="tel:678-722-5664">678-722-5664</a> or{" "}
                  <a href="tel: 855-522-3663"> 855-522-3663</a> or{" "}
                  <NavLink to="/make-an-appointment">
                    <strong>click here</strong>
                  </NavLink>
                  .
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CbMakeAnAppointmentHome;
